"use client"

import { Check, Copy } from "lucide-react"
import { STORAGE_SCOPES, getStoredKey } from "@/lib/headers/storage"

import { HEADER_PERSISTENCE_ANON_ID } from "@/constants/headers"
import { clipboardHandleCopy } from "@/lib/clipboardHandler"
import { useState } from "react"

export default function UserAnonSessionId() {
  const [copied, setCopied] = useState(false)
  const userAnonSessionIdStored = getStoredKey(HEADER_PERSISTENCE_ANON_ID, STORAGE_SCOPES.SESSION)
  if (!userAnonSessionIdStored) return null
  const handleCopySessionId = () => {
    clipboardHandleCopy({
      onCopy: () => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      },
      onError: () => {
        setCopied(false)
      },
      text: userAnonSessionIdStored,
    })
  }

  return (
    <p className="justify-left flex items-center text-balance text-center text-base">
      <span className="mr-2 text-xs">Session ID:</span>
      <button
        data-testid="copy-button"
        className="justify-left group flex items-center"
        onClick={handleCopySessionId}
      >
        <span className="mr-2 text-xs font-bold">{userAnonSessionIdStored}</span>
        {copied ? (
          <Check
            className="hidden h-4 w-4 text-green-500 group-hover:inline-block"
            data-testid="copy-ok"
          />
        ) : (
          <Copy className="hidden h-4 w-4 group-hover:inline-block" data-testid="copy-icon" />
        )}
      </button>
    </p>
  )
}
