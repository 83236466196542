import React from "react"
import { cn } from "@/lib/utils"

interface TypographyProps {
  children: React.ReactNode
  className?: string
}

// 3.75rem
export function TypographyH1({ children, className, ...props }: TypographyProps) {
  return (
    <h1 className={cn("text-6xl font-extrabold", className)} {...props}>
      {children}
    </h1>
  )
}

// 3rem
export function TypographyH2({ children, className, ...props }: TypographyProps) {
  return (
    <h2 className={cn("text-5xl font-bold", className)} {...props}>
      {children}
    </h2>
  )
}

// 2.25rem
export function TypographyH3({ children, className, ...props }: TypographyProps) {
  return (
    <h3 className={cn("text-4xl font-semibold", className)} {...props}>
      {children}
    </h3>
  )
}

// 1.875rem
export function TypographyH4({ children, className, ...props }: TypographyProps) {
  return (
    <h4 className={cn("text-3xl font-semibold", className)} {...props}>
      {children}
    </h4>
  )
}

// 1.5rem
export function TypographyH5({ children, className, ...props }: TypographyProps) {
  return (
    <h5 className={cn("text-2xl font-semibold", className)} {...props}>
      {children}
    </h5>
  )
}
