interface ICopyHandler {
  text: string
  onCopy?: () => void
  onError?: (err: Error | unknown) => void
}

export function clipboardHandleCopy({ text, onCopy, onError }: ICopyHandler) {
  if (!navigator.clipboard) {
    return
  }

  navigator.clipboard.writeText(text).then(
    function () {
      if (onCopy) onCopy()
    },
    function (err) {
      console.error("[Clipboard Handler]: Could not copy text: ", err)
      if (onError) onError(err)
    },
  )
}
