import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { STATUS_HEALTH_DOWN, STATUS_HEALTH_RISK } from "@/constants/status-health"
import { THeaderWithHealthStatus } from "@/types/healthStatus.d"
import { IEventHealthDecorated, IHealthStatus } from "@/types/healthStatus"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const isHealthStatus = (value: IHealthStatus): boolean => {
  return value && typeof value === "object" && "status" in value
}

export const getPriority = (row: IEventHealthDecorated): number => {
  const criteria: THeaderWithHealthStatus[] = ["source", "transcode", "publish", "cdn"]

  for (const criterion of criteria) {
    const criterionValue: IHealthStatus = row[criterion]

    if (isHealthStatus(criterionValue)) {
      if (criterionValue.status === STATUS_HEALTH_DOWN) return 1
      if (criterionValue.status === STATUS_HEALTH_RISK) return 2
    }
  }
  return 3
}

export const sortRows = (rows: IEventHealthDecorated[]): IEventHealthDecorated[] =>
  rows.sort((a: IEventHealthDecorated, b: IEventHealthDecorated) => {
    return getPriority(a) - getPriority(b)
  })

export const numberFormatter = (value: number) =>
  Intl.NumberFormat(navigator.language, { notation: "compact" }).format(value)

const truncatedToTwoDecimals = (num: number) => Math.trunc(num * 100) / 100

export const formatToPercentageOrNumber = (
  type: "sec" | "plays" | "%" | undefined,
  value: number | null | undefined,
): string => {
  if (value === undefined || value === null) return "-"
  switch (type) {
    case "plays":
      return numberFormatter(value)
    case "sec":
      return `${truncatedToTwoDecimals(value)} sec`
    case "%":
      return `${truncatedToTwoDecimals(value)}%`
    default:
      return `${value}`
  }
}
