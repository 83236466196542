/**
 * HEADERS DEFINITIONS
 */

// x-slet-anon-id: This is created every time a user interacts with the web app.
// Identify a persistence session for A USER within the lifetime of a flow in a device.
// Needs to be persisted in a session mode in the browser.
export const HEADER_PERSISTENCE_ANON_ID = "x-slet-anon-id"

// x-slet-correlation-id: This is a uuid created on every request made by the web app.
// Group the request generated from the web app across the layers.
export const HEADER_CORRELATION_ID = "x-slet-correlation-id"

// x-slet-request-id: This is a uuid created on every request from an artifact to artifact.
// Trace each request between layers.
export const HEADER_REQUEST_ID = "x-slet-request-id"

// x-slet-client-name: This is the artifact name that is calling another artifact.
export const HEADER_CLIENT_NAME = "x-slet-client-name"

// x-slet-application-name: The name of the client artifact that started the action,
// for now: “SLET Console” is the only accepted.
export const HEADER_APPLICATION_NAME = "x-slet-application-name"

// x-slet-session-id: (Optional and ONLY captured on the web app)
// Share the Authentication Session ID with all the layers if its required.
export const HEADER_SESSION_ID = "x-slet-session-id"

// x-slet-session-token: (Optional and ONLY transported between web app and APIs)
// Share the Authorization Token to all the layers if its required.
export const HEADER_SESSION_TOKEN = "x-slet-session-token"

// x-slet-interaction-group: Identify the END USER interaction with the application.
// This can be send for more than one action.
export const HEADER_INTERACTION_GROUP = "x-slet-interaction-group"
// x-slet-action-name: Identify the APPLICATION interactions and what actions the user does.
// This should be sended with a unique x-slet-interaction-group to be grouped.
export const HEADER_ACTION_NAME = "x-slet-action-name"

/**
 * HEADERS VALID VALUES
 */

// Applications Allowed
export const APPLICATIONS_ALLOWED_NAME_SLET = "slet-console"
