"use client" // Error boundaries must be Client Components

import { Button } from "@/components/ui/button"
import { CircleX } from "lucide-react"
import { TypographyH1 } from "@/components/ui/typography/headings"
import UserAnonSessionId from "@/components/common/user-anon-session-id"
import { useEffect } from "react"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <div
        data-testid="auth-error"
        className="flex min-h-screen flex-col items-center justify-center gap-4"
      >
        <CircleX size={60} className="text-gray-400" data-testid="circle-alert-icon" />
        <TypographyH1 className="text-2xl">Something went wrong!</TypographyH1>
        <UserAnonSessionId />
        <p className="text-balance text-center">
          <Button
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Try again
          </Button>
        </p>
      </div>
    </div>
  )
}
