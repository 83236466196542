export const STATUS_HEALTH_DOWN = "down"
export const STATUS_HEALTH_RISK = "risk"
export const STATUS_HEALTH_OK = "ok"
export const STATUS_HEALTH_EMPTY = "empty"
export const SECTION_HEALTH = {
  otherEvents: "",
  penaltyBox: "Penalty Box",
}
export const TABLE_COLUMN_NAMES_HEALTH_STATUS = {
  cdn: "CDN",
  details: "Alert Details",
  event: "Event Name",
  health: "Health",
  operator: "Operator",
  pid: "PID",
  property: "Property",
  publish: "Publish",
  source: "Source",
  status: "Status",
  transcode: "Transcode",
}
