import { isBrowserRequest } from "@/lib/browser"
import { v4 as uuid } from "uuid"

export const STORAGE_SCOPES = {
  LOCAL: "local",
  SESSION: "session",
}

/**
 * Generate a UUID and store it in local storage as default but also can use session storage
 * @param key Identifier for the stored UUID
 * @param scope Type of storage to use
 * @returns The stored UUID or a new one if it doesn't exist
 */
export function generateStoredUUID(key: string, scope: string = STORAGE_SCOPES.LOCAL) {
  if (!isBrowserRequest) return null
  const storage = scope === STORAGE_SCOPES.LOCAL ? localStorage : sessionStorage
  const storedId = storage.getItem(key)

  if (storedId) return storedId

  const id = uuid()
  storage.setItem(key, id)

  return id
}

/**
 * Return the stored value for a given key in local storage or session storage
 * @param key Key to retrieve from storage
 * @param scope Type of storage to use (local or session)
 * @returns The stored value
 */
export function getStoredKey(key: string, scope: string = STORAGE_SCOPES.LOCAL) {
  if (!isBrowserRequest) return null
  const storage = scope === STORAGE_SCOPES.LOCAL ? localStorage : sessionStorage
  return storage.getItem(key)
}

/**
 * Store a key-value pair in local storage or session storage
 * @param key Key to store
 * @param value Value to store
 * @param scope Type of storage to use (local or session)
 */
export function setStoredKey(key: string, value: string, scope: string = STORAGE_SCOPES.LOCAL) {
  if (!isBrowserRequest) return null
  const storage = scope === STORAGE_SCOPES.LOCAL ? localStorage : sessionStorage
  storage.setItem(key, value)
}
